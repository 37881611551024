<template>
  <v-dialog v-model="visible" max-width="30vw" @click:outside="close">
    <v-card class="pa-4">
      <v-card-title class="d-flex text-h5 justify-center">
        {{ t("blockList.add_trucker_title") }}
      </v-card-title>
      <div class="mx-10 mb-8">
        <v-divider />
      </div>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="selectedTrucker"
            :label="t('blockList.search_trucker')"
            :items="truckers"
            :item-title="formatTruckerName"
            item-value="id"
            variant="outlined"
            clearable
            :placeholder="t('blockList.search_trucker_placeholder')"
            prepend-inner-icon="mdi-magnify"
            :return-object="true"
            @update:search="debouncedSearch($event)"
          >
            <template #item="{ props: itemProps, item }">
              <v-list-item v-bind="itemProps" :subtitle="item.raw.id">
                <template #title>
                  <div class="d-flex justify-space-between">
                    <span>
                      {{ item.raw.firstName }} {{ item.raw.lastName }}
                    </span>
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="reason"
            :label="t('blockList.reason')"
            :placeholder="t('blockList.enter_reason')"
            variant="outlined"
            rows="3"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center mb-4">
        <v-btn class="px-6" variant="tonal" size="large" @click="close">
          {{ t("cancel") }}
        </v-btn>
        <v-btn
          class="px-6 ml-8"
          variant="flat"
          color="primary"
          size="large"
          @click="confirm"
          :disabled="!selectedTrucker"
        >
          {{ t("blockList.block") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { TerminalOperatorTruckerTO } from "@/services/client/generated";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTerminalOperatorBlockListLogic } from "./terminal-operator-block-list-logic";
import { debounce } from "lodash";

const { t } = useI18n();
const { fetchTruckersByLastName } = useTerminalOperatorBlockListLogic();

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const selectedTrucker = ref<TerminalOperatorTruckerTO | null>(null);
const truckers = ref<TerminalOperatorTruckerTO[]>([]);

const emit = defineEmits(["update:modelValue", "block-trucker"]);

const reason = ref("");

const visible = ref(props.modelValue);

const formatTruckerName = (trucker: TerminalOperatorTruckerTO) => {
  return `${trucker.firstName} ${trucker.lastName}`;
};

function close() {
  emit("update:modelValue", false);
  selectedTrucker.value = null;
  truckers.value = [];
  reason.value = "";
}

function confirm() {
  emit("block-trucker", {
    truckerId: selectedTrucker.value?.id,
    blockedReason: reason.value,
  });
  close();
}

const searchTerm = async (search: string) => {
  if (search.length >= 3) {
    const data = await fetchTruckersByLastName(search);
    truckers.value = data;
  } else {
    truckers.value = [];
  }
};

const debouncedSearch = debounce(searchTerm, 300);

watch(
  () => props.modelValue,
  newValue => {
    visible.value = newValue;
  },
);
</script>
