<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <AddOneToOneRestriction @added-restriction="addRestriction" />
    </v-col>
    <v-col>
      <v-list>
        <AttributeRow :fields="restrictionFields" spacer />
        <transition-group tag="div" name="list">
          <v-list-item
            class="pa-0 pt-4"
            v-for="item in oneToOneRestrictions"
            :key="item.id"
          >
            <OneToOneRestrictionLine
              @deleted-restriction="deleteRestriction"
              :one-to-one-restriction="item"
            />
          </v-list-item>
        </transition-group>
      </v-list>
    </v-col>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import AddOneToOneRestriction from "@/views/terminal-operator/one-to-one-restriction/AddOneToOneRestriction.vue";
import OneToOneRestrictionLine from "@/views/terminal-operator/one-to-one-restriction/OneToOneRestrictionLine.vue";
import { OneToOneRestriction } from "@/services/client/generated";
import { getErrorByType } from "@/utils/error-utils";
import AttributeRow from "@/components/AttributeRow.vue";
import { getViewModel } from "../acceptance-deadline/acceptance-restriction-logic";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import type { Error } from "@/utils/error-utils";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();

const oneToOneRestrictions = ref<OneToOneRestriction[]>([]);
const restrictionFields = ref(["periodOfRestriction"]);

const fetchRestrictions = async (): Promise<void> => {
  oneToOneRestrictions.value = await viewModel.getOneToOneRestrictions();
};

const addRestriction = async (inputData: {
  restrictionDate: string;
  startHour: number;
  endHour: number;
}): Promise<void> => {
  try {
    await viewModel.addOneToOneRestriction(inputData);
    await fetchRestrictions();
  } catch (e) {
    const errorMessage = getErrorByType(e as Error);
    await viewModel.showSnackbarError(errorMessage as string);
  }
};

const deleteRestriction = async (
  restriction: OneToOneRestriction,
): Promise<void> => {
  try {
    await viewModel.deleteOneToOneRestriction(restriction);
    await fetchRestrictions();
  } catch (e) {
    const errorMessage = getErrorByType(e as Error);
    await viewModel.showSnackbarError(errorMessage as string);
  }
};

onMounted(async () => {
  await fetchRestrictions();
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await fetchRestrictions();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
