<template>
  <div>
    <div v-if="userSelectedId.length !== 0">
      <ConfirmationDialog
        :user-id="userSelectedId"
        @submit="(t: any) => resetUser(t.id)"
        @close="(t: any) => (openResetDialog = t.close)"
        :custom-text="t('userManagement.reset_id')"
        :custom-submit="t('accept')"
        :custom-cancel="t('cancel')"
        v-model="openResetDialog"
      />
      <HistoryDialog
        v-if="currentUser != null"
        @approve="(t: any) => approveUser(t, userSelectedId)"
        @close="(t: any) => (openHistoryDialog = t.close)"
        v-model="openHistoryDialog"
        :users="currentUser?.changes"
      />
    </div>
    <v-data-table
      :items="props.users"
      :headers="headers"
      item-value="userId"
      :row-props="getRowClass"
    >
      <template #item.userId="{ item }">
        {{ item.userId }}
      </template>
      <template #item.phoneNumber="{ item }">
        {{ item.phoneNumber != null ? t("phone") : t("email") }}
      </template>
      <template #item.email="{ item }">
        {{ item.email ?? t("missing") }}
      </template>
      <template #item.phoneNumberStatus="{ item }">
        {{ item.phoneNumber ?? t("missing") }}
      </template>
      <template #item.terrorState="{ item }">
        <EditableDropdown
          :selected-value="changeToSend.terrorStatus"
          @select="
            (t: IspsTruckerChangeTOTerrorStatusEnum | undefined) =>
              (changeToSend.terrorStatus = t)
          "
          :is-editable="currentlyEditableRow === item.userId"
          :entries="validTerrorStates"
          :title="
            getLastArrayElement(item.truckerIdentification!)?.terrorStatus ?? ''
          "
        />
      </template>
      <template #item.documentState="{ item }">
        <EditableDropdown
          :selected-value="changeToSend.documentStatus"
          @select="
            (t: IspsTruckerChangeTODocumentStatusEnum | undefined) =>
              (changeToSend.documentStatus = t)
          "
          :is-editable="currentlyEditableRow === item.userId"
          :entries="validIdentificationStates"
          :title="
            getLastArrayElement(item.truckerIdentification!)?.documentStatus ??
            ''
          "
        />
      </template>
      <template #item.firstName="{ item }">
        <EditableInput
          :title="item.firstName"
          @update:model-value="
            (t: string | undefined) => (changeToSend.firstName = t)
          "
          :is-editable="currentlyEditableRow === item.userId"
          v-model="changeToSend.firstName"
          placeholder="firstname"
        />
      </template>
      <template #item.lastName="{ item }">
        <EditableInput
          :title="item.lastName"
          @update:model-value="
            (t: string | undefined) => (changeToSend.lastName = t)
          "
          :is-editable="currentlyEditableRow === item.userId"
          v-model="changeToSend.lastName"
          placeholder="lastname"
        />
      </template>
      <template #item.birth="{ item }">
        {{ getBirthday(item) }}
      </template>
      <template #item.verificationStarted="{ item }">
        {{ getVerificationDate(item) }}
      </template>
      <template #item.lastEditedDate="{ item }">
        {{ getLastEditedDate(item) }}
      </template>
      <template #item.actions="{ item }">
        <v-row>
          <v-col
            v-if="currentlyEditableRow !== item.userId"
            class="d-flex justify-space-between"
          >
            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => changeCurrentlyEditable(item.userId!)"
                >
                  <v-icon icon="mdi-pencil" />
                </v-btn>
              </template>
              <span>{{ t("edit") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showUserHistory(item.userId!)"
                >
                  <v-icon icon="mdi-history" />
                </v-btn>
              </template>
              <span>{{ t("userManagement.show_user_history") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showResetUser(item.userId!)"
                >
                  <v-icon icon="mdi-refresh" />
                </v-btn>
              </template>
              <span>{{ t("userManagement.reset_idnow_tries") }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-else class="d-flex justify-space-between">
            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="tonal"
                  color="green"
                  v-bind="activatorProps"
                  @click="applyChanges"
                >
                  <v-icon icon="mdi-check" />
                </v-btn>
              </template>
              <span>{{ t("save") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  color="elevated"
                  v-bind="activatorProps"
                  @click="discardChanges"
                >
                  <v-icon icon="mdi-close" />
                </v-btn>
              </template>
              <span>{{ t("discard") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from "vue";
import ConfirmationDialog from "@/components/user-management/ConfirmationDialog.vue";
import HistoryDialog from "./HistoryDialog.vue";
import EditableDropdown from "@/components/user-management/EditableDropdown.vue";
import EditableInput from "@/components/user-management/EditableInput.vue";
import { deleteAllKeys, getLastArrayElement } from "@/utils/object-util";
import { dateService } from "@/services/business/date-service";

import {
  IspsTruckerChangeRequestTOTerrorStatusEnum,
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
} from "@/services/client/generated";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const validIdentificationStates: IspsTruckerChangeTODocumentStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const validTerrorStates: IspsTruckerChangeRequestTOTerrorStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const currentlyEditableRow = ref<string>("");
const userSelectedId = ref<string>("");

const snackBarStore = useSnackbarStore();

const openHistoryDialog = ref(false);
const openResetDialog = ref(false);

const currentUser = computed<IspsTruckerTO | undefined>(
  () => props.users.filter(e => e.userId === userSelectedId.value)[0],
);
const changeToSend = reactive<IspsTruckerChangeTO>({});

const changeCurrentlyEditable = (userId: string) => {
  currentlyEditableRow.value = userId;
  deleteAllKeys(changeToSend);
};

const discardChanges = () => {
  currentlyEditableRow.value = "";
  deleteAllKeys(changeToSend);
};

const emit = defineEmits<{
  submit: [payload: { userId: string; data: IspsTruckerChangeTO }];
  approve: [payload: { userId: string; entryId: number; approve: boolean }];
  reset: [payload: string];
}>();

const getBirthday = (user: IspsTruckerTO): string => {
  //some util might be needed
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].dateOfBirth?.toString();

  if (result === undefined) {
    return "";
  }

  return dateService.convertTimeToLocalReadable(new Date(result));
};

const getVerificationDate = (user: IspsTruckerTO): string => {
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].verified_date?.toString();

  if (result === undefined) {
    return "";
  }

  return dateService.convertTimeToLocalReadable(new Date(result));
};

const getLastEditedDate = (user: IspsTruckerTO): string => {
  const lastChange = user.changes?.[user.changes.length - 1]?.createdDate;
  return lastChange
    ? dateService.convertTimeToLocalReadable(new Date(lastChange))
    : "";
};

const isChangeValid = (change: IspsTruckerChangeTO): boolean => {
  return Object.keys(change).length !== 0;
};

const applyChanges = () => {
  const validChange = isChangeValid(changeToSend);
  if (!validChange) {
    snackBarStore.showSnackbar({
      text: "Can't add an empty change",
      snackbarType: SnackbarType.ERROR,
    });
    return;
  }
  const payload = {
    userId: currentlyEditableRow.value,
    data: { ...changeToSend },
  };
  emit("submit", payload);
  currentlyEditableRow.value = "";
  deleteAllKeys(changeToSend);
};

const showResetUser = (userId: string) => {
  userSelectedId.value = userId;
  openResetDialog.value = !openResetDialog.value;
};

const showUserHistory = (userId: string) => {
  userSelectedId.value = userId;
  openHistoryDialog.value = !openHistoryDialog.value;
};

const approveUser = (
  payload: { approve: boolean; id: number },
  userId: string,
) => {
  const { approve, id } = payload;
  emit("approve", { userId, entryId: id, approve });
};

const resetUser = (payload: string) => {
  emit("reset", payload);
};

const headers = ref([
  { title: t("user_id"), value: "userId", align: "center" },
  {
    title: t("account_type"),
    value: "phoneNumber",
    align: "center",
  },
  { title: t("email"), value: "email", align: "center" },
  {
    title: "Phone number",
    value: "phoneNumberStatus",
    align: "center",
  },
  {
    title: t("userManagement.terror_state"),
    value: "terrorState",
    align: "center",
  },
  {
    title: t("userManagement.document_state"),
    value: "documentState",
    align: "center",
  },
  { title: t("first_name"), value: "firstName", align: "center" },
  { title: t("last_name"), value: "lastName", align: "center" },
  { title: t("birth"), value: "birth", align: "center" },
  {
    title: t("userManagement.verification_started"),
    value: "verificationStarted",
    align: "center",
  },
  { title: t("last_edited_date"), value: "lastEditedDate", align: "center" },
  { title: "", value: "actions", sortable: false },
] as const);

const getRowClass = (item: { item: IspsTruckerTO }) => {
  const user = item.item;
  const rowClass = {
    "bg-orange-400":
      getLastArrayElement(user.truckerIdentification!)?.documentStatus ===
      "WARN",
    "bg-red-accent-2":
      user.changes?.filter(e => e.state === "PENDING").length !== 0,
  };
  return { class: rowClass };
};

const props = defineProps<{ users: IspsTruckerTO[] }>();
</script>
