import { computed } from "vue";
import { useDisplay } from "vuetify";
import { getViewModel } from "@/views/train-operator/bookings-page/train-operator-bookings-logic";

export function useBaseTextSize() {
  const displayName = useDisplay().name;
  const viewModel = getViewModel();

  const baseTextSize = computed((): string => {
    return viewModel.baseTextSize(displayName.value);
  });

  return {
    baseTextSize,
  };
}
