<template>
  <div>
    <div v-if="loading">
      <v-progress-circular indeterminate color="red" />
    </div>

    <div v-else class="mt-6">
      <v-select
        class="select"
        density="compact"
        :items="forwarders"
        :item-props="true"
        :label="$t('forwarders')"
        item-title="name"
        v-model="selectedForwarder"
        @update:modelValue="changeForwarder"
        variant="outlined"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { Forwarder } from "@/services/client/generated";

const authStore = useAuthStore();
const forwarderStore = useForwarderStore();

const forwarders = ref<Forwarder[]>([]);
const selectedForwarder = ref<string | null>(null);
const loading = ref(true);

const fetchForwarders = async (): Promise<void> => {
  const userId = authStore.authUser?.userId;
  if (userId) {
    forwarders.value = await forwarderStore.getForwarders(userId);
    const forwarder = forwarderStore.getForwarder();
    if (forwarder) {
      selectedForwarder.value = forwarder.name;
    } else if (forwarders.value && forwarders.value.length > 0) {
      selectedForwarder.value = forwarders.value[0].name;
      forwarderStore.setForwarderData(forwarders.value[0]);
    }
  }

  loading.value = false;
};

const changeForwarder = async () => {
  if (selectedForwarder.value && forwarders.value) {
    const forwarderEntity = forwarders.value.find(
      e => e.name === selectedForwarder.value,
    );
    if (forwarderEntity) {
      forwarderStore.setForwarderData(forwarderEntity);
    }
  }
};

onMounted(async () => {
  await fetchForwarders();
});
</script>

<style scoped>
.loading-title {
  font-weight: bold;
  font-size: 2vh;
}

.select {
  width: 15vw;
}
</style>
