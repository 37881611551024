<template>
  <v-card
    class="card rounded-lg"
    color="#bfc9d7"
    width="100%"
    elevation="2"
    rounded
  >
    <v-card-title class="card-title">
      <v-row align-content="space-around" class="d-flex align-center">
        <v-col cols="3">{{ trainNumber }}</v-col>
        <v-col cols="3">{{ departureDate }}</v-col>
        <v-col cols="4">{{ acceptanceDeadlineDate }}</v-col>
        <v-col cols="2">
          <v-icon @click="dialog = true" size="x-large" color="#ff005b">
            mdi-close-circle-outline
          </v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <ConfirmationDialog
      :dialog="dialog"
      :question-text="$t('deleteConfirmationAcceptanceDeadline')"
      @confirm="deleteDeadline"
      @deny="dialog = false"
    />
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, PropType } from "vue";
import { AcceptanceDeadline } from "@/services/client/generated/api";
import { getErrorByType } from "@/utils/error-utils";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { getViewModel } from "./acceptance-restriction-logic";
import type { Error } from "@/utils/error-utils";

const viewModel = getViewModel();
const dialog = ref(false);
const props = defineProps({
  acceptanceDeadline: {
    type: Object as PropType<AcceptanceDeadline>,
    required: true,
  },
});
const emit = defineEmits(["deleted-deadline"]);

const trainNumber = computed(() => {
  if (props.acceptanceDeadline?.trains) {
    return props.acceptanceDeadline.trains[0].trainNumber;
  }
  return "";
});

const departureDate = computed(() => {
  return viewModel.parseDepartureDateForElement(props.acceptanceDeadline);
});

const acceptanceDeadlineDate = computed(() => {
  return viewModel.parseAcceptanceDeadlineDateForElement(
    props.acceptanceDeadline,
  );
});

const updateList = (): void => {
  emit("deleted-deadline");
};

const deleteDeadline = async (): Promise<void> => {
  try {
    await viewModel.deleteAcceptanceDeadline(props.acceptanceDeadline);
    dialog.value = false;
    updateList();
  } catch (e) {
    getErrorByType(e as Error);
  }
};
</script>

<style scoped>
.card-title {
  color: #00295b;
  font-size: 1.35em;
}
</style>
