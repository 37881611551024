import PageCardItem from "@/internal-models/page-card-item";
import i18n from "@/plugins/i18n";
import { WebappUserRolesEnum } from "@/services/client/generated";

const homepagePageCards: Record<string, PageCardItem> = {
  trainOperator: {
    background: "operator-chair",
    title: i18n.global.t("trainOperator") as string,
    visible: true,
    page: "/trainOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.TrainOperatorRead,
      WebappUserRolesEnum.TrainOperatorWrite,
    ],
  },
  terminalOperator: {
    background: "terminals",
    title: i18n.global.t("terminalOperator") as string,
    visible: true,
    page: "/terminalOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.TerminalOperationsAdmin,
      WebappUserRolesEnum.TerminalOperationsRead,
      WebappUserRolesEnum.TerminalOperationsWrite,
    ],
  },
  conrooUser: {
    background: "graph",
    title: i18n.global.t("conrooUser") as string,
    visible: true,
    page: "/userManagement",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
    ],
  },
  forwarderOperator: {
    background: "trucks",
    title: `${i18n.global.t("forwarderOperator")}`,
    visible: true,
    page: "/forwarderOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.ForwarderRead,
      WebappUserRolesEnum.ForwarderWrite,
    ],
  },
};

export default homepagePageCards;
