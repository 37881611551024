<template>
  <div>
    <nav>
      <v-app-bar fluid :color="getColorBasedOnEnvironment" theme="dark">
        <v-toolbar-title fluid class="text-uppercase">
          <v-container>
            <v-row align="center" justify="start">
              <v-img :src="imgUrl" max-height="20" max-width="200" />
              <h3 id="main-title" class="hidden-sm-and-down ml-2">
                {{ getCurrentModule }}
              </h3>
            </v-row>
          </v-container>
        </v-toolbar-title>
        <v-spacer />

        <TerminalDropdown
          v-if="
            show() &&
            (hasPermission(groupedItems[1].roles) ||
              hasPermission(groupedItems[2].roles))
          "
          class="mr-3"
          data-testid="test-TerminalDropdown"
        />
        <ForwarderDropdown
          v-if="hasPermission(groupedItems[3].roles)"
          data-testid="test-ForwarderDropdown"
        />
      </v-app-bar>
      <v-navigation-drawer v-if="show()" expand-on-hover rail color="greyish">
        <v-list variant="flat" class="pt-15" nav :items="groupedItems">
          <ListItem
            v-for="(item, i) in groupedItems"
            v-show="hasPermission(item.roles)"
            :item="item"
            :key="i"
          />

          <v-list-item
            @click="logOut"
            prepend-icon="mdi-logout"
            :title="$t('logout')"
            data-testid="test-logout"
            class="navigation-item text-left customPrepend"
          />
        </v-list>
      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { WebappUserRolesEnum } from "@/services/client/generated";
import { hasPermissionBasedOnRole } from "@/utils/role-utils";

import SidebarItem from "@/internal-models/sidebar-item";
import items from "@/constants/sidebar-items";
import imageUrl from "@/assets/main/conroo.png";

import ListItem from "@/layouts/navigation-bar/NavigationListItem.vue";
import TerminalDropdown from "@/layouts/navigation-bar/TerminalDropdown.vue";
import ForwarderDropdown from "@/layouts/navigation-bar/ForwarderDropdown.vue";

import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const router = useRouter();
const authStore = useAuthStore();

const groupedItems = ref<SidebarItem[]>(items);
const imgUrl = ref(imageUrl);

const show = (): boolean => {
  return authStore.authUser !== null;
};

const hasPermission = (pageRoles: WebappUserRolesEnum[]): boolean => {
  const userRoles: WebappUserRolesEnum[] = authStore.userData?.roles ?? [];
  return hasPermissionBasedOnRole(pageRoles, userRoles);
};

const logOut = async (): Promise<void> => {
  await authStore.logout();
  router.push("/login");
};

const getColorBasedOnEnvironment = computed((): string => {
  switch (import.meta.env.VITE_NODE_ENV) {
    case "development":
      return "#FF4136";
    case "staging":
      return "#FFDC00";
    case "production":
      return "primary";
    default:
      return "primary";
  }
});

const getCurrentModule = computed((): string => {
  const path = router.currentRoute.value.path;
  const possiblePaths = [
    "terminalOperator",
    "trainOperator",
    "forwarderOperator",
    "conrooUser",
  ];
  for (const possiblePath of possiblePaths) {
    if (path.includes(possiblePath)) return i18n.t(possiblePath) as string;
  }
  return "";
});
</script>

<style lang="scss" scoped>
#main-title {
  color: rgba(255, 255, 255, 0.5);
}

.navigation-item {
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(var(--v-theme-greyish));
}

.customPrepend :deep(.v-list-item__prepend, .v-list-item__spacer) {
  width: 60px;
}
</style>
