import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./plugins/i18n";
import Vue3Lottie from "vue3-lottie";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "../firebase";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(pinia);
app.use(Vue3Lottie);
app.mount("#app");
