<template>
  <v-container class="pa-10" fluid>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="$t('truckerManagement')"
    />
    <div class="pa-10">
      <AttributeRow :fields="listFields">
        <template #action>
          <v-spacer />
        </template>
      </AttributeRow>
      <transition-group tag="div" name="list" mode="out-in">
        <TruckerManagementRow
          v-for="(trucker, i) in truckers"
          :key="trucker.userId"
          :trucker="trucker"
          :editable="isEditable(i)"
          class="mt-5"
          @approve-trucker="approveTrucker"
          @delete-trucker="deleteTrucker"
          @deny-trucker="denyTrucker"
          @update-trucker="updateTrucker"
          @edit-trucker="editTrucker"
          @cancel-trucker-edit="cancelEdit"
        />
      </transition-group>
    </div>
    <v-skeleton-loader
      v-if="showSkeleton"
      v-intersect="loadNextPage"
      type="list-item@5"
    />
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import AttributeRow from "@/components/AttributeRow.vue";
import {
  TruckerForForwarderTO,
  UpdateTruckerForForwarderTO,
} from "@/services/client/generated";
import TruckerManagementRow from "@/views/forwarder-operator/trucker-management/TruckerManagementRow.vue";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./forwarder-trucker-management-logic";

const forwarderStore = useForwarderStore();
const viewModel = getViewModel();

const truckers = ref<TruckerForForwarderTO[]>([]);
const editable = ref<boolean[]>([]);
const showSkeleton = ref(true);
const page = ref(0);

const listFields: readonly string[] = [
  "registered",
  "email",
  "name",
  "surname",
  "licensePlate",
  "completedBookings",
  "lastCompletedBooking",
];

const fetchTruckers = async (): Promise<void> => {
  const fetchedTruckers = await viewModel.getTruckersForForwarder(page.value);
  if (fetchedTruckers.length !== 0) {
    for (const trucker of fetchedTruckers) {
      if (!truckers.value.some(e => e.userId === trucker.userId)) {
        truckers.value.push(trucker);
      }
    }
    page.value++;
    editable.value = truckers.value.map(() => false);
  } else {
    showSkeleton.value = false;
  }
};

const loadNextPage = async (
  entries: IntersectionObserverEntry[],
): Promise<void> => {
  setTimeout(async () => {
    if (entries) await fetchTruckers();
  }, 400);
};

const isEditable = (index: number): boolean => {
  return editable.value[index] ?? false;
};

const approveTrucker = async (truckerId: string): Promise<void> => {
  await viewModel.approveTrucker(truckerId, true);
  const trucker = truckers.value.find(e => e.userId === truckerId);
  if (trucker && trucker.forwarderApproval)
    trucker.forwarderApproval.approved = true;
  await fetchTruckers();
};

const denyTrucker = async (truckerId: string): Promise<void> => {
  await viewModel.approveTrucker(truckerId, false);
  truckers.value = truckers.value.filter(e => e.userId !== truckerId);
  editable.value = truckers.value.map(() => false);
};

const updateTrucker = async (emmitedData: {
  truckerId: string;
  updateTrucker: UpdateTruckerForForwarderTO;
}): Promise<void> => {
  const returnedTrucker = await viewModel.updateTrucker(
    emmitedData.truckerId,
    emmitedData.updateTrucker,
  );
  const index = truckers.value.findIndex(
    e => e.userId === returnedTrucker.userId,
  );
  if (index !== -1) truckers.value[index] = returnedTrucker;
  editable.value = truckers.value.map(() => false);
};

const deleteTrucker = async (truckerId: string): Promise<void> => {
  await viewModel.deleteTrucker(truckerId);
  truckers.value = truckers.value.filter(e => e.userId !== truckerId);
  editable.value = truckers.value.map(() => false);
};

const editTrucker = (truckerId: string): void => {
  editable.value = truckers.value.map(trucker => trucker.userId === truckerId);
};

const cancelEdit = (): void => {
  editable.value = truckers.value.map(() => false);
};

onMounted(async () => {
  await fetchTruckers();
});

watch(
  () => forwarderStore.forwarder,
  async (newForwarder, oldForwarder) => {
    if (newForwarder?.id !== oldForwarder?.id) {
      await fetchTruckers();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
