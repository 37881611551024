import SidebarItem from "@/internal-models/sidebar-item";
import IconAssets from "@/constants/assets/icon-assets";
import { WebappUserRolesEnum } from "@/services/client/generated";
import i18n from "@/plugins/i18n";

const sidebarItems: SidebarItem[] = [
  {
    title: i18n.global.t("home") as string,
    icon: IconAssets.HOME,
    pageName: "/",
    roles: [],
  },

  {
    title: i18n.global.t("terminalOperator") as string,
    icon: IconAssets.CONTAINER,
    pageName: "/terminalOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.TerminalOperationsAdmin,
      WebappUserRolesEnum.TerminalOperationsRead,
      WebappUserRolesEnum.TerminalOperationsWrite,
    ],
  },

  {
    title: i18n.global.t("trainOperator") as string,
    icon: IconAssets.TRAIN,
    pageName: "/trainOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.TrainOperatorRead,
      WebappUserRolesEnum.TrainOperatorWrite,
    ],
  },

  {
    title: i18n.global.t("forwarderOperator") as string,
    icon: IconAssets.TRUCK,
    pageName: "/forwarderOperator",
    roles: [
      WebappUserRolesEnum.ConrooWrite,
      WebappUserRolesEnum.ConrooRead,
      WebappUserRolesEnum.ConrooAdmin,
      WebappUserRolesEnum.ForwarderRead,
      WebappUserRolesEnum.ForwarderWrite,
    ],
  },
];

export default sidebarItems;
