<template>
  <v-card
    :style="selected ? `border: 4px solid ${getBorderColor} !important;` : ''"
    max-width="max-content"
    class="ml-5 mr-5 ma-2 rounded-lg date-title time-card"
    elevation="5"
    rounded
    :color="getCardColor"
  >
    <v-card-title class="text-white">
      <v-tooltip location="right" :disabled="!isYellowCategory">
        <template #activator="{ props: activatorProps }">
          <v-row v-bind="activatorProps">
            <v-col cols="8" class="text-no-wrap">
              {{ parsedDate }}
            </v-col>
            <v-col cols="4">
              <v-icon color="white">
                {{ getCardIcon }}
              </v-icon>
            </v-col>
          </v-row>
        </template>
        <span>{{ $t("highLoadYellow") }}</span>
      </v-tooltip>
    </v-card-title>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TimeSlot, TimeSlotCategoryEnum } from "@/services/client/generated";

const props = defineProps({
  timeSlot: {
    type: Object as () => TimeSlot,
    required: true,
  },
  parsedDate: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  },
});

const getCardColor = computed(() => {
  switch (props.timeSlot?.category) {
    case TimeSlotCategoryEnum.Green:
      return "green";
    case TimeSlotCategoryEnum.Yellow:
      return "#ffaa00cc";
    case TimeSlotCategoryEnum.Red:
      return "red";
    case TimeSlotCategoryEnum.NotSet:
      return "greyish";
    default:
      throw new Error("Value could not be mapped");
  }
});

const getBorderColor = computed(() => (props.selected ? "#00295b" : "white"));

const isYellowCategory = computed(
  () => props.timeSlot?.category === TimeSlotCategoryEnum.Yellow,
);

const getCardIcon = computed(() => {
  switch (props.timeSlot?.category) {
    case TimeSlotCategoryEnum.Green:
      return "mdi-check";
    case TimeSlotCategoryEnum.Yellow:
      return "mdi-alert";
    case TimeSlotCategoryEnum.Red:
      return "mdi-close-octagon";
    case TimeSlotCategoryEnum.NotSet:
      return "";
    default:
      throw new Error("Value could not be mapped");
  }
});
</script>

<style scoped lang="scss">
.time-card {
  cursor: pointer;
}
</style>
