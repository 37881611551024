<template>
  <div v-if="props.active">
    <transition-group tag="div" name="list" :class="transitionClass">
      <TrainOperatorBookingTileActive
        class="pt-3"
        v-for="item in props.trainOperatorBookings"
        :key="item.bookingId"
        :train-operator-booking="item"
      />
    </transition-group>
  </div>
  <div v-else>
    <transition-group tag="div" name="list" :class="transitionClass">
      <TrainOperatorBookingTileCompleted
        class="pt-3"
        v-for="item in props.trainOperatorBookings"
        :key="item.bookingId"
        v-bind="
          props.checkboxes && {
            'parent-value': props.checkboxes[item.bookingId],
          }
        "
        @toggle-checkbox="toggleCheckbox"
        :train-operator-booking="item"
      />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, onMounted } from "vue";
import { TrainOperatorBooking } from "@/services/client/generated/api";
import TrainOperatorBookingTileActive from "@/views/train-operator/bookings-page/train-operator-booking-tiles/TrainOperatorBookingTileActive.vue";
import TrainOperatorBookingTileCompleted from "@/views/train-operator/bookings-page/train-operator-booking-tiles/TrainOperatorBookingTileCompleted.vue";

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  trainOperatorBookings: {
    type: Array as () => TrainOperatorBooking[],
    required: true,
  },
  checkboxes: {
    type: Object as () => Record<number, boolean>,
    required: false, // only required on Completed tab
    default: () => ({}),
  },
});

const emit = defineEmits(["toggle-checkbox"]);

const toggleCheckbox = (payload: {
  value: boolean;
  bookingId: number;
}): void => {
  emit("toggle-checkbox", payload);
};

const validateProps = () => {
  if (!props.active && !props.checkboxes) {
    console.error("props: Completed tab requires checkboxes");
  }
};

const transitionClass = computed((): string => {
  const prefix = props.active ? "active" : "completed";
  return `${prefix}-bookings`;
});

watch(
  props,
  () => {
    validateProps();
  },
  { immediate: true }
);

onMounted(() => {
  validateProps();
});
</script>
