<template>
  <v-list>
    <v-card class="card rounded-lg" width="100%" rounded>
      <AttributeRow :fields="restrictionFields" />
    </v-card>
    <transition-group tag="div" name="list">
      <v-list-item
        class="pa-0 pt-4"
        v-for="item in displayedRestriction"
        :key="item.id"
      >
        <v-card
          class="card rounded-lg"
          color="#bfc9d7"
          width="100%"
          elevation="2"
          rounded
        >
          <v-card-title class="card-title">
            <v-row>
              <v-col xs="12" sm="6" md="6">{{ item.type }}</v-col>
              <v-col xs="12" sm="6" md="6">{{ item.dates }}</v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-list-item>
    </transition-group>
  </v-list>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { OneToOneRestriction } from "@/services/client/generated";
import AttributeRow from "@/components/AttributeRow.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./get-status-logic";

const viewModel = getViewModel();
const i18n = useI18n();

const props = defineProps({
  oneToOneRestrictions: {
    type: Array as () => OneToOneRestriction[],
    required: true,
  },
});

const restrictionFields = ["type", "periodOfRestriction"];

const displayedRestriction = computed(() => {
  return props.oneToOneRestrictions.map(restriction => {
    const date = viewModel.parseOneToOneRestrictionRange(
      restriction,
      i18n.locale.value,
    );
    return { id: restriction.id, type: "1:1", dates: date };
  });
});
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";

.card-title {
  @media only screen and (min-width: 400px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 1.35rem;
  }
}
</style>
