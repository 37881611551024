import {
  ColorBorderColorEnum,
  ConstantForwarderStatisticFloatValueResponseTO,
  ConstantForwarderStatisticValueRequestTONameEnum,
  RangedTerminalStatisticsValueRequestNameEnum,
  RangedTerminalStatisticsValueResponse,
} from "@/services/client/generated";
//fix vuetify and map the colors there through names and use the colorborder enum directly
// range method

//TODO: ask about a greyish option for this case
export function findBorderColor(
  responseValue:
    | RangedTerminalStatisticsValueResponse
    | ConstantForwarderStatisticFloatValueResponseTO
    | null
): ColorBorderColorEnum {
  if (responseValue?.colorBorders?.length === 0)
    return ColorBorderColorEnum.Blue;
  if (!responseValue || !responseValue.colorBorders)
    return ColorBorderColorEnum.Blue; //default value maybe
  const value = responseValue.floatValue; //TODO: check on using ? operator
  for (const colorBorder of responseValue.colorBorders) {
    if (value >= colorBorder.minValue && value <= colorBorder.maxValue) {
      return colorBorder.color;
    }
  }

  return ColorBorderColorEnum.Red;
}

//TODO: remove TO
export function findSeriesBorderColor(
  values: number[],
  responseValue:
    | RangedTerminalStatisticsValueResponse
    | ConstantForwarderStatisticFloatValueResponseTO
): ColorBorderColorEnum[] {
  return values.map(_ => findBorderColor(responseValue));
}

export function getStatisticTypeFromStringForTerminal(
  type: string
): RangedTerminalStatisticsValueRequestNameEnum {
  switch (type) {
    case "active_bookings":
      return RangedTerminalStatisticsValueRequestNameEnum.ActiveBookings;
    case "completed_bookings":
      return RangedTerminalStatisticsValueRequestNameEnum.CompletedBookings;

    case "canceled_bookings":
      return RangedTerminalStatisticsValueRequestNameEnum.CanceledBookings;
    case "time_register_success_transhipment":
      return RangedTerminalStatisticsValueRequestNameEnum.TimeRegisterSuccessTranshipment;
    default:
      throw new Error("Statistic value type not found");
  }
}

export function getStatisticTypeFromStringForForwarder(
  type: string
): ConstantForwarderStatisticValueRequestTONameEnum {
  switch (type) {
    case "open_orders":
      return ConstantForwarderStatisticValueRequestTONameEnum.OpenOrders;
    case "registered_truckers":
      return ConstantForwarderStatisticValueRequestTONameEnum.RegisteredTruckers;
    default:
      throw new Error("Statistic value type not found");
  }
}
