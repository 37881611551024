import PageCardItem from "@/internal-models/page-card-item";
import IconAssets from "@/constants/assets/icon-assets";
import i18n from "@/plugins/i18n";

const forwarderOperatorCards: Record<string, PageCardItem> = {
  statusRequest: {
    icon: IconAssets.HOME_ICON,
    background: "terminals",
    title: i18n.global.t("statusRequest") as string,
    visible: true,
    page: "forwarderOperator/statusRequest",
  },
  truckerManagement: {
    icon: IconAssets.MENU,
    background: "operator-chair",
    title: i18n.global.t("truckerManagement") as string,
    visible: true,
    page: "forwarderOperator/truckerManagement",
  },
  analyticalDashboard: {
    icon: IconAssets.ACCEPTANCE_DEADLINE,
    background: "graph",
    title: i18n.global.t("analyticalDashboard") as string,
    visible: true,
    page: "forwarderOperator/analyticalDashboard",
  },
  bookingsOverview: {
    icon: IconAssets.HEADPHONES,
    background: "trucks",
    title: i18n.global.t("bookingsOverview") as string,
    visible: true,
    page: "forwarderOperator/bookingsOverview",
  },
};

export default forwarderOperatorCards;
