import Duration from "@/internal-models/enums/duration";
import TimeRange from "@/internal-models/time-range";

const dropdownRanges: Array<TimeRange> = [
  { duration: Duration.DAYS, amount: 1 },
  { duration: Duration.WEEKS, amount: 1 },
  { duration: Duration.MONTHS, amount: 1 },
  { duration: Duration.YEARS, amount: 1 },
];

export default dropdownRanges;
