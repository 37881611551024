import PageCardItem from "@/internal-models/page-card-item";
import IconAssets from "@/constants/assets/icon-assets";
import i18n from "@/plugins/i18n";

const terminalOperatorPageCards: Record<string, PageCardItem> = {
  blocklist: {
    icon: IconAssets.BLOCK_USER,
    background: "terminals",
    title: i18n.global.t("blockList.title") as string,
    visible: true,
    page: "terminalOperator/blockList",
  },
  deadlines: {
    icon: IconAssets.ACCEPTANCE_DEADLINE,
    background: "operator-chair",
    title: i18n.global.t("deadlines") as string,
    visible: true,
    page: "terminalOperator/deadlines",
  },
  modulemanagment: {
    icon: IconAssets.MENU,
    background: "graph",
    title: i18n.global.t("moduleManagment") as string,
    visible: true,
    page: "terminalOperator/modulemanagment",
  },
  bookingOverview: {
    icon: IconAssets.MENU,
    background: "trucks",
    title: i18n.global.t("bookingOverview") as string,
    visible: true,
    page: "terminalOperator/bookingOverview",
  },
};

export default terminalOperatorPageCards;
