export interface Error {
  code?: number | string;
  response?: {
    status?: number;
    data?: {
      code?: number | string;
      message?: string;
    };
  };
}

export function getErrorByType(error: Error): string | number {
  if (error.response?.data?.code) {
    return error.response.data.code;
  }

  // If no data code, check for specific status codes
  if (error.response?.status) {
    const statusCode = error.response.status;
    if (statusCode === 403 || statusCode === 401) {
      return "user_not_found";
    }

    // If the status code is not handled specifically, return "unknownError"
    return "unknownError";
  }

  // If it's not an AxiosError or doesn't have a response, check for error.code
  if (error.code) {
    return error.code;
  }

  // Fallback if no specific error type is found
  return "unknownError";
}
