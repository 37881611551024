<template>
  <v-form v-model="valid">
    <v-card
      class="card rounded-lg"
      color="#bfc9d7"
      width="100%"
      elevation="2"
      rounded
    >
      <v-card-title class="card-title pa-5">
        <v-row align="center">
          <v-col cols="1" class="items" align-self="center">
            {{ missing(registeredTruckerDate) }}
          </v-col>
          <v-col
            cols="2"
            class="items d-inline-block text-truncate-container"
            align-self="center"
          >
            <v-tooltip location="bottom">
              <template #activator="{ props: isActive }">
                <span v-bind="isActive" class="text-truncate-content">
                  {{ missing(trucker.email) }}
                </span>
              </template>
              <span>{{ missing(trucker.email) }}</span>
            </v-tooltip>
          </v-col>

          <v-col cols="2" class="items" align-self="center">
            <EditableField
              :value="name"
              :placeholder="$t('name')"
              :rules="[validateMissingInput]"
              class="pa-2"
              color="#bfc9d7"
              @input-change="v => (name = v)"
              :readonly="editable"
            />
          </v-col>

          <v-col cols="2" class="items" align-self="center">
            <EditableField
              :value="lastName"
              :placeholder="$t('surname')"
              class="pa-2"
              color="#bfc9d7"
              :rules="[validateMissingInput]"
              @input-change="v => (lastName = v)"
              :readonly="editable"
            />
          </v-col>

          <v-col cols="1" class="items" align-self="center">
            <EditableField
              :value="licensePlate"
              :placeholder="$t('licensePlate')"
              class="pa-2"
              color="#bfc9d7"
              :rules="[validateMissingInput]"
              @input-change="v => (licensePlate = v)"
              :readonly="editable"
            />
          </v-col>

          <v-col cols="1" class="items" align-self="center">
            {{ missing(trucker.completedBookingsCount?.toString()) }}
          </v-col>

          <v-col cols="1" class="items" align-self="center">
            {{ missing(lastCompletedBookingDate) }}
          </v-col>

          <v-col cols="2" class="items" align-self="center">
            <v-row
              class="approval-tiles"
              v-if="!trucker?.forwarderApproval?.approved"
            >
              <v-col cols="6" align-self="center">
                <div
                  @click="$emit('approve-trucker', trucker.userId)"
                  class="text-green font-weight-bold cursor text--uppercase"
                >
                  {{ $t("approve") }}
                </div>
              </v-col>
              <v-col
                cols="6"
                align-self="center"
                class="text-red font-weight-bold cursor text--uppercase"
                @click="$emit('deny-trucker', trucker.userId)"
              >
                {{ $t("deny") }}
              </v-col>
            </v-row>

            <v-row class="pr-2" v-else-if="!editable" justify="center">
              <v-col cols="3">
                <v-icon
                  @click="$emit('edit-trucker', trucker.userId)"
                  size="30"
                  color="#3f5d85"
                >
                  mdi-pencil
                </v-icon>
              </v-col>
              <v-col cols="3">
                <v-icon
                  @click="deletionDialog = true"
                  size="30"
                  color="#3f5d85"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6">
                <v-icon @click="updateTrucker" size="30" color="green">
                  mdi-check
                </v-icon>
              </v-col>
              <v-col cols="6">
                <v-icon @click="cancelTruckerEdit" size="30" color="red">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <ConfirmationDialog
      :dialog="deletionDialog"
      :question-text="$t('deleteTruckerConfirmation')"
      @confirm="deleteTrucker"
      @deny="deletionDialog = false"
    />
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, PropType } from "vue";
import { TruckerForForwarderTO } from "@/services/client/generated";
import EditableField from "@/components/EditableField.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./forwarder-trucker-management-logic";

const viewModel = getViewModel();
const i18n = useI18n();
const props = defineProps({
  trucker: {
    type: Object as PropType<TruckerForForwarderTO>,
    required: true,
    default: () => ({}) as TruckerForForwarderTO,
  },
  editable: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  "approve-trucker",
  "cancel-trucker-edit",
  "update-trucker",
  "delete-trucker",
  "deny-trucker",
  "edit-trucker",
]);

const valid = ref(false);
const name = ref("");
const lastName = ref("");
const licensePlate = ref("");
const deletionDialog = ref(false);

onMounted(() => {
  name.value = props.trucker.firstName ?? "";
  lastName.value = props.trucker.lastName ?? "";
  licensePlate.value = props.trucker.licensePlate ?? "";
});

const validateMissingInput = (v: string): boolean | string => {
  return !!v || (i18n.t("fieldRequired") as string);
};

const cancelTruckerEdit = (): void => {
  name.value = props.trucker.firstName ?? "";
  lastName.value = props.trucker.lastName ?? "";
  licensePlate.value = props.trucker.licensePlate ?? "";
  emit("cancel-trucker-edit", props.trucker.userId);
};

const updateTrucker = (): void => {
  if (valid.value) {
    emit("update-trucker", {
      truckerId: props.trucker.userId,
      updateTrucker: {
        firstName: name.value,
        lastName: lastName.value,
        licensePlate: licensePlate.value,
      },
    });
  }
};

const deleteTrucker = (): void => {
  deletionDialog.value = false;
  emit("delete-trucker", props.trucker.userId);
};

const missing = (value: string | null | undefined): string => {
  try {
    if (value == null || value.length === 0) {
      return i18n.t("missing") as string;
    }
    return value;
  } catch (_error) {
    return i18n.t("missing") as string;
  }
};

const registeredTruckerDate = computed((): string => {
  if (!props.trucker || !props.trucker.registeredDate) {
    return i18n.t("missing") as string;
  }
  return viewModel.parseIsoIntoDate(
    props.trucker.registeredDate,
    i18n.locale.value,
  );
});

const lastCompletedBookingDate = computed((): string => {
  if (!props.trucker || !props.trucker.lastBookingCompletedDate) {
    return i18n.t("missing") as string;
  }
  return viewModel.parseIsoIntoDateTime(
    props.trucker.lastBookingCompletedDate,
    i18n.locale.value,
  );
});
</script>

<style lang="scss" scoped>
.card-title {
  color: #00295b;
  word-break: unset;

  //TODO: can maybe wrap into a scss file

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.approval-tiles {
  @media only screen and (min-width: 400px) {
    font-size: 0.6rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 0.6rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 0.8rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1rem;
  }
}

.items {
  padding: 0;
}

.cursor {
  cursor: pointer;
}

.text-truncate-container {
  display: inline-block;
  width: 100%;
}

.text-truncate-content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
