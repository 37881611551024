<template>
  <v-card
    :width="!$vuetify.display.mdAndDown ? '55vw' : '90vw'"
    :height="!$vuetify.display.mdAndDown ? '500' : '350'"
    :loading="loadingResetPassword"
  >
    <v-main fluid class="fill-height pa-6">
      <v-row>
        <v-col
          :cols="!$vuetify.display.mdAndDown ? 6 : 12"
          class="d-flex align-center justify-center"
        >
          <div class="w-100 mx-8">
            <div class="text-h4 font-weight-bold">
              {{ $t("forgotPassword") }}
            </div>

            <v-card-text class="mt-8">
              <v-form v-model="valid">
                <v-text-field
                  v-model="email"
                  type="email"
                  prepend-icon="mdi-email"
                  :rules="[
                    (v: string) =>
                      /^[^\s@]+@[^\s@]+$/.test(v) || $t('invalidEmail'),
                  ]"
                  :error-messages="errorMessages"
                  label="E-mail"
                  variant="outlined"
                  :placeholder="$t('placeholderEmail')"
                  required
                  :disabled="loadingResetPassword"
                  :loading="loadingResetPassword"
                />

                <TheButton
                  :is-disabled="loadingResetPassword"
                  :color="'primary'"
                  :size="'large'"
                  :rounded="'lg'"
                  @clicked-button="sendResetEmail"
                  :elevation="0"
                  class="mt-6"
                  :title="$t('sendResetEmail')"
                />
              </v-form>
            </v-card-text>

            <TheErrorBox
              class="ml-15 mt-2"
              :title="forgotPasswordErrorMessage"
            />

            <TheMainText
              @click="changeFormView"
              :clickable="true"
              :title="$t('back_to_login')"
            />
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.display.mdAndDown"
          cols="6"
          class="d-flex align-center justify-center"
        >
          <div class="animation-container rounded-lg">
            <Vue3Lottie
              :animation-data="ForgotPassword"
              :loop="true"
              :autoplay="true"
              :height="450"
              :max-height="450"
            />
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { Vue3Lottie } from "vue3-lottie";
import { useI18n } from "vue-i18n";
import TheButton from "@/components/TheButton.vue";
import TheMainText from "@/components/TheMainText.vue";
import TheErrorBox from "@/components/TheErrorBox.vue";
import { Error, getErrorByType } from "@/utils/error-utils";
import { getViewModel } from "./login-page-logic";
import ForgotPassword from "../../../lottie/ForgotPassword.json";

const emit = defineEmits(["changed-login-view"]);
const { t } = useI18n();
const viewModel = getViewModel();

const valid = ref(false);
const forgotPasswordErrorMessage = ref("");
const loadingResetPassword = ref(false);
const errorMessages = ref("");
const email = ref("");

// computed properties
const validate = computed(() => valid.value);

// methods
const startProcess = (): void => {
  forgotPasswordErrorMessage.value = "";
  loadingResetPassword.value = true;
};

const stopProcess = (): void => {
  loadingResetPassword.value = false;
};

const sendResetEmail = async (): Promise<void> => {
  if (!validate.value) {
    forgotPasswordErrorMessage.value = "";
    return;
  }
  if (loadingResetPassword.value) return;
  startProcess();
  try {
    await viewModel.resetPassword(email.value);
    changeFormView();
    await viewModel.showEmailSentSnackbar();
  } catch (e) {
    forgotPasswordErrorMessage.value = t(getErrorByType(e as Error)) as string;
  } finally {
    stopProcess();
  }
};

const changeFormView = (): void => {
  emit("changed-login-view");
};
</script>

<style lang="css">
.animation-container {
  background-color: #14005c;
}
</style>
