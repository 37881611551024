<template>
  <v-dialog v-model="model" max-width="30vw" absolute class="text-center">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("blockList.confirm_delete_title") }}
      </v-card-title>
      <v-card-text>
        <i18n-t keypath="blockList.confirm_delete_message">
          <template #name>
            <b>
              {{ selectedTrucker?.firstName }} {{ selectedTrucker?.lastName }}
            </b>
          </template>
        </i18n-t>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn variant="text" @click="emit('cancel-delete')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="red" @click="emit('delete-trucker')">
          {{ $t("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { TerminalOperatorTruckerTO } from "@/services/client/generated";
import { PropType, toRefs } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  selectedTrucker: {
    type: Object as PropType<TerminalOperatorTruckerTO>,
    required: true,
  },
});

const { modelValue: model } = toRefs(props);

const emit = defineEmits(["delete-trucker", "cancel-delete"]);
</script>
