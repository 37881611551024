<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col align-self="center" class="d-flex justify-center">
        <v-list
          style="min-height: 300px; max-height: 300px"
          class="overflow-y-auto"
        >
          <TimeSlotDateRow
            v-for="(day, index) in Object.keys(groupedTimeSlots)"
            :day="day"
            @click="() => changeDay(day, index)"
            :active="activeDaySlots[index]"
            :key="index"
          />
        </v-list>
      </v-col>
      <v-col align-self="center" class="d-flex justify-center">
        <v-list
          style="min-height: 300px; max-height: 300px"
          class="overflow-y-auto"
        >
          <TimeSlotTimeRow
            v-for="(timeSlot, index) in showingTimeSlots"
            :time-slot="timeSlot"
            @click="() => selectTimeSlot(timeSlot, index)"
            :selected="activeTimeSlots[index]"
            :parsed-date="getParsedTimeSlotTime(timeSlot)"
            :key="index"
          />
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, PropType, reactive } from "vue";
import TimeSlotTimeRow from "@/views/forwarder-operator/get-status/TimeSlotTimeRow.vue";
import TimeSlotDateRow from "@/views/forwarder-operator/get-status/TimeSlotDateRow.vue";
import { TimeSlot } from "@/services/client/generated";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./get-status-logic";

const i18n = useI18n();

const viewModel = getViewModel();
const emits = defineEmits(["select-timeSlot", "change-day"]);

const props = defineProps({
  timeSlots: {
    type: Array as PropType<TimeSlot[]>,
    required: true,
    default: () => [],
  },
});

const groupedTimeSlots = reactive<Record<string, TimeSlot[]>>({});
const activeDaySlots = ref<boolean[]>([]);
const activeTimeSlots = ref<boolean[]>([]);
const showingTimeSlots = ref<TimeSlot[]>([]);

const groupTimeslotsByDay = (
  timeSlots: TimeSlot[],
): Record<string, TimeSlot[]> => {
  const timeSlotResult: Record<string, TimeSlot[]> = {};
  for (const timeSlot of timeSlots) {
    const dayOfTimeSlot = viewModel.getDayOfTimeSlot(
      timeSlot,
      i18n.locale.value,
    );
    if (!timeSlotResult[dayOfTimeSlot]) {
      timeSlotResult[dayOfTimeSlot] = [];
      emits("select-timeSlot", null);
    }
    timeSlotResult[dayOfTimeSlot].push(timeSlot);
  }
  return timeSlotResult;
};

const changeDay = (day: string, index: number) => {
  activeDaySlots.value = activeDaySlots.value.map((e, i) => i === index && !e);
  if (activeDaySlots.value.filter(e => e).length === 0) {
    showingTimeSlots.value = [];
  } else {
    activeTimeSlots.value = groupedTimeSlots[day].map(() => false);
    showingTimeSlots.value = groupedTimeSlots[day] ?? [];
  }
  emits("change-day", { day });
};

const getParsedTimeSlotTime = (timeSlot: TimeSlot): string => {
  if (!timeSlot) return "";
  return viewModel.getHoursOfTimeSlot(timeSlot);
};

const selectTimeSlot = (timeSlot: TimeSlot, index: number) => {
  emits("select-timeSlot", timeSlot);
  activeTimeSlots.value = activeTimeSlots.value.map(
    (e, i) => i === index && !e,
  );
};

onMounted(() => {
  const grouped = groupTimeslotsByDay(props.timeSlots as TimeSlot[]);
  Object.keys(grouped).forEach(key => {
    groupedTimeSlots[key] = grouped[key];
  });
  activeDaySlots.value = props.timeSlots?.map(() => false);
});
</script>

<style scoped lang="scss"></style>
