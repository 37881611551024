const IconAssets: Record<string, string> = {
  //material icons
  ACCEPTANCE_DEADLINE: "mdi-view-dashboard",
  HEADPHONES: "mdi-headphones-box",
  MENU: "mdi-menu",
  LOGOUT: "mdi-logout",
  SUCCESS: " mdi-checkbox-marked-circle",
  WARNING: "mdi-alert",
  ERROR: "mdi-alert-circle",
  ACCOUNT: "mdi-account",
  HOME_ICON: "mdi-home",
  BLOCK_USER: "mdi-account-cancel",

  //svg icons
  HOME: "HomeIcon",
  CONTAINER: "ContainerIcon",
  TRAIN: "TrainIcon",
  TRUCK: "TruckIcon",
};

export default IconAssets;
