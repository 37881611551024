import { isEqual } from "lodash";

type GroupedCollection = Record<string, object[]>;

export function getAllValuesForSameKeyInCollection(
  collection: Record<string, object>[]
): GroupedCollection {
  const result: GroupedCollection = {};
  collection.forEach(v => {
    for (const [key, value] of Object.entries(v)) {
      const duplicate = checkIfDuplicate(result, key, value);
      if (!duplicate) {
        addIfAbsent(result, key, value);
      }
    }
  });

  return result;
}

function checkIfDuplicate(
  collection: GroupedCollection,
  key: string,
  value: object
): boolean {
  if (typeof value === "object") {
    return collection[key]?.some(e => isEqual(e, value)) ?? false;
  } else {
    return collection[key]?.includes(value) ?? false;
  }
}

function addIfAbsent(
  record: GroupedCollection,
  key: string,
  value: object | string
) {
  if (!record[key]) {
    record[key] = [];
  }
  record[key].push(value as object);
}
