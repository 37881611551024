<template>
  <div>
    <v-card
      class="rounded-lg d-flex align-self-center py-4 font-weight-medium"
      :color="cardColor"
      elevation="2"
      rounded
    >
      <v-row class="flex-nowrap" align="center">
        <v-col
          v-for="(column, i) in columnData"
          :key="i"
          :style="column.style"
          :class="column.class"
          :id="column.id"
        >
          {{ column.data }}
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TrainOperatorBooking } from "@/services/client/generated/api";
import ColumnData from "@/internal-models/column-data";
import { ACTIVE_COLUMNS } from "@/constants/train-operator-booking-constants";
import { getViewModel } from "../train-operator-bookings-logic";

const props = defineProps({
  trainOperatorBooking: {
    type: Object as () => TrainOperatorBooking,
    required: true,
  },
});

const viewModel = getViewModel();

const activeStrippedColumnNames: string[] = ACTIVE_COLUMNS.map(columnId => {
  let strippedColumnName: string = columnId;
  const columnNameParts: string[] = columnId.split(".");
  if (columnNameParts.length > 1) {
    strippedColumnName = columnNameParts[1];
  }
  return strippedColumnName;
});

const cardColor = computed((): string => {
  return viewModel.cardColor(props.trainOperatorBooking);
});

const columnData = computed((): ColumnData[] => {
  const activeColumnData = viewModel
    .allColumnData(props.trainOperatorBooking)
    .filter(data => activeStrippedColumnNames.includes(data.id));
  return activeColumnData;
});
</script>

<style lang="scss" scoped>
.tile-text {
  color: #00295b;
  padding: 0;
  text-align: center;
  width: 5vw;
}

.remove-most-whitespace :deep(.v-input--selection-controls__input) {
  padding: 0px;
  margin: 0px 0px 0px 15px;
}

.v-input--checkbox :deep(*) {
  .v-label,
  .v-icon {
    color: #00295b;
  }
}
</style>
