<template>
  <v-container class="pa-10" fluid>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="$t('bookingsOverview')"
    />
    <div class="pa-10">
      <div v-if="!bookings" class="pa-10">
        <v-progress-linear indeterminate />
      </div>
      <div v-else-if="bookings.length === 0 && noFiltersAssigned">
        <v-row align="center" justify="center" class="fill-height">
          <v-col class="main-title" sm="6" md="3">
            <v-col>{{ $t("noBookingsFound") }}</v-col>
            <v-col>
              <v-img fluid cover src="../../../assets/main/error-404.png" />
            </v-col>
            <v-col>
              <TheButton
                @clicked-button="goBack"
                :loading="false"
                :title="$t('goBack')"
              />
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <FilterableAttributeRow
          :attributes="availableFilters"
          @filter-addition="addToActiveFilter"
          spacer
        />
      </div>
      <transition-group tag="div" name="list">
        <BookingDetailsRow
          @delete-booking="deleteBooking"
          @download-transhipment="downloadTranshipment"
          v-for="(booking, i) in bookings"
          :booking="booking"
          :key="i"
          class="mt-5"
        />
      </transition-group>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import {
  BookingForForwarderTO,
  BookingsFilterRequestTO,
} from "@/services/client/generated";
import BookingDetailsRow from "@/views/forwarder-operator/bookings-overview/BookingDetailsRow.vue";
import FilterableAttributeRow from "@/views/forwarder-operator/bookings-overview/FilterableAttributeRow.vue";
import TheButton from "@/components/TheButton.vue";
import { useI18n } from "vue-i18n";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { getViewModel } from "./forwarder-bookings-overview-logic";
import { useRouter } from "vue-router";

const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const router = useRouter();
const viewModel = getViewModel();

const bookings = ref<BookingForForwarderTO[] | null>(null);
const availableFilters = ref<Record<string, object[]>>({});
const filterRequest = ref<BookingsFilterRequestTO>({});
const { t } = useI18n();

const goBack = () => {
  router.go(-1);
};

const refreshState = async (): Promise<void> => {
  bookings.value = await viewModel.getBookingsForForwarder(filterRequest.value);
  availableFilters.value = viewModel.mapBookingValuesIntoUIComponent(
    bookings.value,
  );
};

const addToActiveFilter = async (payload: {
  label: string;
  value: object;
}): Promise<void> => {
  if (payload.label === "terminal") payload.label = "terminalName";
  if (payload.label === "truckerName") payload.label = "truckerId";

  (filterRequest.value as Record<string, object>)[payload.label] =
    payload.value;
  await refreshState();
};

const deleteBooking = async (bookingId: number): Promise<void> => {
  const booking = bookings.value?.find(e => e.id === bookingId);
  if (booking && booking.truckerInformation && booking.truckerInformation.id) {
    await viewModel.deleteBooking(bookingId, booking.truckerInformation.id);
  }
  await refreshState();
};

const downloadTranshipment = async (bookingId: number): Promise<void> => {
  const booking = bookings.value?.find(e => e.id === bookingId);
  if (booking && booking.truckerInformation && booking.truckerInformation.id) {
    const name = [
      t("transhipment"),
      booking.referenceNumber,
      booking.containerNumber,
    ]
      .filter(e => e)
      .join("-");
    await viewModel.downloadTranshipment(
      bookingId,
      booking.truckerInformation.id,
      name,
    );
  }
};

const noFiltersAssigned = computed((): boolean => {
  const filterAsObject = filterRequest.value as Record<string, unknown>;
  for (const key in filterAsObject) {
    if (filterAsObject[key]) return false;
  }
  return true;
});

onMounted(async () => {
  await refreshState();
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await refreshState();
    }
  },
);
</script>

<style scoped lang="scss">
@import "../../../scss/list-transitions";
</style>
