<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <AddAcceptanceDeadline @created-deadline="fetchAcceptanceDeadlines" />
    </v-col>
    <v-col>
      <v-list>
        <AttributeRow :fields="deadlineFields" spacer />
        <transition-group tag="div" name="list">
          <v-list-item
            class="pa-0 pt-4"
            v-for="(item, i) in acceptanceDeadlines"
            :key="i"
          >
            <AcceptanceDeadlineLine
              @deleted-deadline="fetchAcceptanceDeadlines"
              :acceptance-deadline="item"
            />
          </v-list-item>
        </transition-group>
      </v-list>
    </v-col>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import AcceptanceDeadlineLine from "@/views/terminal-operator/acceptance-deadline/AcceptanceDeadlineLine.vue";
import AddAcceptanceDeadline from "@/views/terminal-operator/acceptance-deadline/AddAcceptanceDeadline.vue";
import { AcceptanceDeadline } from "@/services/client/generated/api";
import AttributeRow from "@/components/AttributeRow.vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./acceptance-restriction-logic";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();

const acceptanceDeadlines = ref<AcceptanceDeadline[] | null>(null);
const deadlineFields = ref<string[]>(["trainNumber", "moveDate", "period"]);

const fetchAcceptanceDeadlines = async (): Promise<void> => {
  acceptanceDeadlines.value = await viewModel.getAcceptanceDeadlines();
};

onMounted(async () => {
  await fetchAcceptanceDeadlines();
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await fetchAcceptanceDeadlines();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
