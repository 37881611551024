<template>
  <div>
    <v-card
      class="card rounded-lg"
      :color="colorOfCard"
      width="100%"
      elevation="2"
      rounded
    >
      <v-card-title class="card-title pa-5">
        <v-row align-content="space-around">
          <v-col class="items text-wrap" align-self="center">
            {{ missing(getBookingStatus(booking.status)) }}
          </v-col>
          <v-col class="items text-wrap" align-self="center">
            {{ missing($t(booking.type ?? "missing")) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(booking.referenceNumber) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(booking.containerNumber) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(booking.containerType) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{
              missing(
                booking.truckerInformation
                  ? booking.truckerInformation.firstName +
                      " " +
                      booking.truckerInformation.lastName
                  : undefined,
              )
            }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(booking.licensePlate) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(booking.terminalName) }}
          </v-col>

          <v-col class="items text-wrap" align-self="center">
            {{ missing(parseTimeslot(booking.timeslot)) }}
          </v-col>

          <v-col>
            <v-icon
              v-if="booking.status === 'completed'"
              size="x-large"
              color="#00295B"
              @click="downloadTranshipment"
            >
              mdi-file-download-outline
            </v-icon>
            <div v-else-if="booking.status === 'transhipment'">
              <v-spacer></v-spacer>
            </div>
            <v-icon
              v-else
              @click="dialog = true"
              size="x-large"
              color="#ff005b"
            >
              mdi-close-circle-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <ConfirmationDialog
        :dialog="dialog"
        :question-text="$t('deleteConfirmationBooking')"
        @confirm="deleteBooking"
        @deny="dialog = false"
      />
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import i18n from "@/plugins/i18n";
import {
  BookingForForwarderTO,
  BookingForForwarderTOStatusEnum,
  TimeSlot,
} from "@/services/client/generated";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { getViewModel } from "./forwarder-bookings-overview-logic";

const props = defineProps<{
  booking: BookingForForwarderTO;
}>();
const emit = defineEmits(["download-transhipment", "delete-booking"]);
const viewModel = getViewModel();
const dialog = ref(false);
const downloadTrigger = ref(-1);
const triggerThreshold = 2000;

const getBookingStatus = (
  bookingStatus: BookingForForwarderTOStatusEnum | undefined,
): string => {
  if (bookingStatus === undefined) {
    return i18n.global.t("missing");
  }

  switch (bookingStatus) {
    case BookingForForwarderTOStatusEnum.Active:
      return i18n.global.t("booked");
    case BookingForForwarderTOStatusEnum.RegistrationBlocked:
      return i18n.global.t("registrationBlocked");
    case BookingForForwarderTOStatusEnum.RegistrationRestricted:
      return i18n.global.t("registrationRestricted");
    case BookingForForwarderTOStatusEnum.Registered:
    case BookingForForwarderTOStatusEnum.Transhipment:
    case BookingForForwarderTOStatusEnum.TerminalReached:
      return i18n.global.t("terminalReached");
    case BookingForForwarderTOStatusEnum.Completed:
      return i18n.global.t("completed");
    case BookingForForwarderTOStatusEnum.Cancelled:
      return i18n.global.t("cancelled");
    case BookingForForwarderTOStatusEnum.CounterRequired:
      return i18n.global.t("counterRequired");
  }
};

const downloadTranshipment = (): void => {
  const currentTime = new Date().getTime();
  if (downloadTrigger.value !== -1) {
    if (currentTime - downloadTrigger.value < triggerThreshold) return;
  }
  downloadTrigger.value = currentTime;
  emit("download-transhipment", props.booking.id);
};

const parseTimeslot = (timeslot: TimeSlot | undefined | null): string => {
  if (!timeslot) return i18n.global.t("missing") as string;
  return viewModel.parseTimeslot(timeslot, i18n.global.locale.value);
};

const deleteBooking = (): void => {
  dialog.value = false;
  emit("delete-booking", props.booking.id);
};

const missing = (value: string | undefined | null): string => {
  if (!value) return i18n.global.t("missing") as string;
  return value;
};

const colorOfCard = computed((): string => {
  switch (props.booking.status) {
    case BookingForForwarderTOStatusEnum.Active:
      return "#fddc7f";
    case BookingForForwarderTOStatusEnum.RegistrationBlocked:
    case BookingForForwarderTOStatusEnum.RegistrationRestricted:
    case BookingForForwarderTOStatusEnum.Cancelled:
    case BookingForForwarderTOStatusEnum.CounterRequired:
      return "#b72003c8";
    case BookingForForwarderTOStatusEnum.Registered:
    case BookingForForwarderTOStatusEnum.Transhipment:
    case BookingForForwarderTOStatusEnum.TerminalReached:
      return "#fddc7f";
    case BookingForForwarderTOStatusEnum.Completed:
      return "#c9e08c";
    default:
      throw new Error("Value should be part of enum");
  }
});
</script>

<style scoped lang="scss">
.card-title {
  color: #00295b;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.items {
  padding: 0;
}
</style>
