import {
  forwarderTruckersClient,
  ispsTerminalOperatorBlockList,
  webappTruckersClient,
} from "@/services/client/configs/services";
import {
  ListTruckersForFacilityBlockedStateEnum,
  TerminalOperatorTruckerTO,
  TruckerBlocklistRequestTO,
} from "@/services/client/generated";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { ref, watch } from "vue";

export function useTerminalOperatorBlockListLogic() {
  const authStore = useAuthStore();
  const terminalStore = useTerminalStore();
  const forwarderStore = useForwarderStore();
  const truckers = ref<TerminalOperatorTruckerTO[]>([]);

  async function getBlockListForFacility() {
    if (authStore.authUser && terminalStore.terminal) {
      const { data } = await webappTruckersClient.listTruckersForFacility(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        undefined,
        undefined,
        undefined,
        ListTruckersForFacilityBlockedStateEnum.Blocked,
      );
      truckers.value = data;
    }
  }

  async function fetchTruckersByLastName(lastName: string) {
    if (authStore.authUser && terminalStore.terminal) {
      const { data } = await webappTruckersClient.listTruckersForFacility(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        undefined,
        undefined,
        lastName,
      );
      return data;
    }
    return [];
  }

  async function deleteBlockListEntry(id: number) {
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.deleteBlockListEntryForIspsTerminal(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        id,
      );
      await getBlockListForFacility();
    }
  }

  async function addBlockListEntry(truckerRequest: TruckerBlocklistRequestTO) {
    console.log(truckerRequest);
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.createBlockListEntryForIspsTerminal(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        truckerRequest,
      );
      await getBlockListForFacility();
    }
  }

  const fetchTruckers = async () => {
    if (authStore.authUser && forwarderStore.forwarder) {
      const { data } = await forwarderTruckersClient.getAllTruckersForForwarder(
        authStore.authUser.userId,
        forwarderStore.forwarder.id,
      );

      return data;
    }
    return [];
  };

  watch(
    () => [terminalStore.terminal, forwarderStore.forwarder],
    async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
      if (
        newTerminal?.id !== oldTerminal?.id ||
        newForwarder?.id !== oldForwarder?.id
      ) {
        await getBlockListForFacility();
      }
    },
  );

  return {
    truckers,
    fetchTruckersByLastName,
    getBlockListForFacility,
    deleteBlockListEntry,
    addBlockListEntry,
    fetchTruckers,
  };
}
