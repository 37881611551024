<template>
  <div>
    <v-card color="#00295B" class="rounded-b-lg">
      <v-container fluid>
        <v-row class="bookings-title pl-2 d-flex align-center">
          <v-col cols="3">{{ $t("bookings") }}</v-col>
          <v-col cols="10" md="3" sm="4" v-if="tabName === 'completed'">
            <TheButton
              :title="$t('trainOperatorBookings.completeSelection')"
              @clicked-button="dialog = true"
              :is-disabled="disabled"
              color="green"
              size="default"
            />
          </v-col>
        </v-row>
        <v-row class="flex-nowrap">
          <v-col
            v-if="tabName === 'completed'"
            class="pa-0 ml-2 d-flex justify-center align-center"
          >
            <v-checkbox
              hide-details
              class="pt-0 mt-0 remove-all-whitespace"
              color="white"
              v-model="value"
              @update:modelValue="onCheckboxClicked"
            />
          </v-col>
          <v-col
            :style="{ 'font-size': baseTextSize }"
            :key="i"
            v-for="(column, i) in props.columns"
            class="columns d-flex justify-center align-center"
          >
            <v-tooltip location="top" :disabled="!isTooltipColumn(column)">
              <template #activator="{ props: tooltipProps }">
                <div v-bind="tooltipProps">
                  {{ $t(column) }}
                </div>
              </template>
              <span v-if="isTooltipColumn(column)">
                {{ $t(`${column}Tooltip`) }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <ConfirmationDialog
      :dialog="dialog"
      :question-text="$t('markTrainBookingAsCompleted')"
      @confirm="deleteBooking"
      @deny="dialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import TheButton from "@/components/TheButton.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { useBaseTextSize } from "@/composables/useBaseTextSize";

const props = defineProps({
  parentValue: {
    type: Boolean,
    required: false, // only required on Completed tab
  },
  tabName: {
    type: String,
    required: true,
  },
  columns: {
    type: Array as () => string[],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits(["delete-selected-bookings", "toggle-all"]);

const value = ref(false);
const dialog = ref(false);

const deleteBooking = (): void => {
  dialog.value = false;
  emit("delete-selected-bookings");
};

const onCheckboxClicked = (e: boolean | null): void => {
  value.value = e === true;
  emit("toggle-all", e);
};

const isTooltipColumn = (column: string): boolean => {
  return [
    "trainOperatorBookings.containerNumber",
    "trainOperatorBookings.lastLoadingTime",
    "trainOperatorBookings.trainNumber",
  ].includes(column);
};

const { baseTextSize } = useBaseTextSize();

watch(
  () => props.parentValue,
  newValue => {
    value.value = newValue === true;
  },
);
</script>

<style scoped lang="scss">
.bookings-title {
  font-weight: bold;
  color: white;
  font-size: 2.2em;
}

.columns {
  margin: 0;
  color: white;
  text-align: center;
}

.remove-all-whitespace :deep(.v-input--selection-controls__input) {
  padding: 0px;
  margin: 0px;
}

.v-input--checkbox :deep(*) {
  .v-label,
  .v-icon {
    color: white;
  }
}
</style>
