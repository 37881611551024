import { h, Component } from "vue";
import type { IconSet, IconProps } from "vuetify";
import ContainerIcon from "@/assets/icons/ContainerIcon.vue";
import HomeIcon from "@/assets/icons/HomeIcon.vue";
import TrainIcon from "@/assets/icons/TrainIcon.vue";
import TruckIcon from "@/assets/icons/TruckIcon.vue";

const customSvgNameToComponent: Record<string, Component> = {
  ContainerIcon,
  HomeIcon,
  TrainIcon,
  TruckIcon,
};

const customSVGs: IconSet = {
  component: (props: IconProps) => {
    const iconComponent = customSvgNameToComponent[props.icon];
    return iconComponent ? h(iconComponent) : null;
  },
};

export { customSVGs };
