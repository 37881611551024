<template>
  <v-card
    class="card rounded-lg"
    color="#bfc9d7"
    width="100%"
    elevation="2"
    rounded
  >
    <v-card-title class="card-title">
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>
        <v-col class="d-flex align-center">{{ restrictionDate }}</v-col>
        <v-col>
          <v-icon @click="dialog = true" size="x-large" color="#ff005b">
            mdi-close-circle-outline
          </v-icon>
        </v-col>
      </v-row>
    </v-card-title>

    <ConfirmationDialog
      :dialog="dialog"
      :question-text="$t('deleteConfirmationAcceptanceDeadline')"
      @confirm="deleteRestriction"
      @deny="dialog = false"
    />
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { OneToOneRestriction } from "@/services/client/generated";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "../acceptance-deadline/acceptance-restriction-logic";

const viewModel = getViewModel();
const i18n = useI18n();

const props = defineProps({
  oneToOneRestriction: {
    type: Object as () => OneToOneRestriction,
    required: true,
  },
});

const emit = defineEmits(["deleted-restriction"]);

const dialog = ref(false);

const deleteRestriction = () => {
  dialog.value = false;
  emit("deleted-restriction", props.oneToOneRestriction);
};

const restrictionDate = computed(() => {
  return viewModel.parseOneToOneRestrictionRange(
    props.oneToOneRestriction,
    i18n.locale.value,
  );
});
</script>

<style lang="scss" scoped>
.card-title {
  color: #00295b;
}

.card-title {
  @media only screen and (min-width: 400px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 1.35rem;
  }
}
</style>
