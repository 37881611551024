<template>
  <v-main fluid class="fill-height main">
    <v-container fluid class="fill-height">
      <v-row
        align="center"
        justify="center"
        class="justify-center align-center"
      >
        <div class="d-flex flex-column align-center">
          <p class="font-weight-bold text-h2 pb-8 text-white">
            {{ $t("welcome_to_conroo") }}
          </p>
          <LoginForm
            v-if="loginForm"
            @changed-login-view="toggleView"
            :error-messages="errorMessages"
            :login-error-message="loginErrorMessage"
            :loading-login-button="loadingLoginButton"
            @submit-login="login"
          />
          <ForgotPasswordForm @changed-login-view="toggleView" v-else />
        </div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import LoginForm from "@/views/general/login-page/LoginForm.vue";
import ForgotPasswordForm from "@/views/general/login-page/ForgotPasswordForm.vue";
import { Error, getErrorByType } from "@/utils/error-utils";
import { useAuthStore } from "@/store/useAuthStore";

const authStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();

const errorMessages = ref("");
const loginForm = ref(true);
const loginErrorMessage = ref("");
const loadingLoginButton = ref(false);

// methods
const toggleView = (): void => {
  loginForm.value = !loginForm.value;
};

const startProcess = (): void => {
  loginErrorMessage.value = "";
  loadingLoginButton.value = true;
};

const stopProcess = (): void => {
  loadingLoginButton.value = false;
};

const login = async (payload: {
  email: string;
  password: string;
  validate: boolean | null;
}): Promise<void> => {
  if (!payload.validate) {
    loginErrorMessage.value = "";
    return;
  }
  if (loadingLoginButton.value) return;
  startProcess();
  try {
    await authStore.login({ email: payload.email, password: payload.password });
    router.push("/");
  } catch (e) {
    await authStore.logout();
    loginErrorMessage.value = t(getErrorByType(e as Error)) as string;
    router.push("/login");
  } finally {
    stopProcess();
  }
};
</script>

<style scoped>
.main {
  background-image: url("../../../assets/main/conroo-background.svg");
  background-size: cover;
  height: 100%;
}
</style>
