<template>
  <div>
    <v-container class="dynamic-width-container">
      <v-row fluid class="pl-5 pt-10" align="center" justify="space-between">
        <v-col cols="4">
          <TheMainHeader class="text-left" :text="$t('checkBookingData')" />
        </v-col>
        <v-col cols="2" class="pr-10" align="end">
          <TrainOperatorDropdown
            v-if="manyTrainOperators"
            @update:modelValue="switchTrainOperator"
            :train-operators="trainOperators"
          />
        </v-col>
        <v-col cols="12">
          <v-row class="pt-5" align="center" justify="center" no-gutters>
            <v-col cols="12">
              <v-tabs
                align-tabs="center"
                grow
                v-model="tab"
                bg-color="white"
                @update:modelValue="switchTab"
              >
                <v-tab class="text-black" href="#active">
                  <div class="tab-title">{{ $t("active") }}</div>
                </v-tab>
                <v-tab class="text-black" href="#completed">
                  <div class="tab-title">{{ $t("completed") }}</div>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="fill-height" fluid>
        <v-col cols="12" class="pa-5">
          <v-window v-model="tab">
            <v-window-item :key="1" value="active">
              <TrainOperatorsToolBar
                tab-name="active"
                :columns="columnsArray('active')"
              />
              <TrainOperatorBookingTileList
                :active="true"
                :train-operator-bookings="trainOperatorBookings"
              />
            </v-window-item>

            <v-window-item :key="2" value="completed">
              <v-card flat>
                <TrainOperatorsToolBar
                  tab-name="completed"
                  :columns="columnsArray('completed')"
                  :disabled="
                    !Object.values(checkboxes).some(
                      checkboxChecked => checkboxChecked === true,
                    )
                  "
                  @toggle-all="toggleAllCheckboxes"
                  :parent-value="allSelected"
                  @delete-selected-bookings="confirmBookings"
                  size="small"
                />
                <TrainOperatorBookingTileList
                  v-if="Object.keys(checkboxes).length !== 0"
                  :checkboxes="checkboxes"
                  :active="false"
                  :train-operator-bookings="trainOperatorBookings"
                  @toggle-checkbox="toggleCheckbox"
                />
              </v-card>
            </v-window-item>
          </v-window>
          <v-skeleton-loader
            v-if="showSkeleton"
            v-intersect="loadNextPage"
            type="list-item@5"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TrainOperatorsToolBar from "@/views/train-operator/bookings-page/TrainOperatorsToolBar.vue";
import TrainOperatorBookingTileList from "./TrainOperatorBookingTileList.vue";
import TrainOperatorDropdown from "@/views/train-operator/bookings-page/TrainOperatorDropdown.vue";
import {
  TrainOperator,
  TrainOperatorBooking,
} from "@/services/client/generated";
import {
  ACTIVE_COLUMNS,
  COMPLETED_COLUMNS,
} from "@/constants/train-operator-booking-constants";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./train-operator-bookings-logic";
import DropdownItem from "@/internal-models/dropdown-item";

const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const viewModel = getViewModel();
const PAGE_SIZE = 25;

const tab = ref("active");
const active = ref(true);
const allSelected = ref(false);
const selectedTrainOperator = ref(-1);
const page = ref(0);
const size = ref(PAGE_SIZE);
const trainOperators = ref<TrainOperator[]>([]);
const checkboxes = ref<Record<number, boolean>>({});
const showSkeleton = ref(true);
const trainOperatorBookings = ref<TrainOperatorBooking[]>([]);
const _columns = ref<string[]>(ACTIVE_COLUMNS);

const manyTrainOperators = computed(() => {
  return trainOperators.value.length > 0;
});

const getTrainOperators = async (): Promise<void> => {
  trainOperators.value = await viewModel.getTrainOperators();
  selectedTrainOperator.value = trainOperators.value[0].id;
};

const getOperatorBookings = async (isActive: boolean): Promise<void> => {
  if (selectedTrainOperator.value === -1) return;
  const loadedBookings = await viewModel.getBookingsForTrainOperator(
    selectedTrainOperator.value,
    page.value,
    size.value,
    isActive,
  );
  if (loadedBookings.length !== 0) {
    for (const item of loadedBookings) {
      if (!trainOperatorBookings.value.includes(item)) {
        trainOperatorBookings.value.push(item);
        if (!isActive) {
          checkboxes.value[item.bookingId] = false;
          allSelected.value = false;
        }
      }
    }
    checkboxes.value = { ...checkboxes.value };
    page.value++;
  } else {
    showSkeleton.value = false;
  }
};

const toggleAllCheckboxes = (value: boolean) => {
  trainOperatorBookings.value.forEach(e => {
    checkboxes.value[e.bookingId] = value;
  });
  checkboxes.value = { ...checkboxes.value };
  allSelected.value = true;
};

const toggleCheckbox = (payload: { value: boolean; bookingId: number }) => {
  checkboxes.value[payload.bookingId] = payload.value;
  checkboxes.value = { ...checkboxes.value };
  if (!payload.value) {
    allSelected.value = payload.value;
  }

  const checkedBookings = Object.values(checkboxes.value).filter(e => e);
  if (checkedBookings.length === trainOperatorBookings.value.length) {
    allSelected.value = true;
  }
};

const confirmBookings = async () => {
  const selectedBookings = trainOperatorBookings.value
    .map(x => x.bookingId)
    .filter(bookingId => checkboxes.value[bookingId] === true);
  await viewModel.confirmBookingForTrainOperator(
    selectedBookings,
    selectedTrainOperator.value,
  );
  const remainingBookings: TrainOperatorBooking[] = [];
  for (let i = 0; i < trainOperatorBookings.value.length; i++) {
    const currentBooking = trainOperatorBookings.value[i];
    const bookingId: number = currentBooking.bookingId;
    if (checkboxes.value[bookingId] === false) {
      remainingBookings.push(currentBooking);
    } else {
      delete checkboxes.value[bookingId];
    }
  }
  trainOperatorBookings.value = remainingBookings;
};

const loadNextPage = async (
  entries: IntersectionObserverEntry[],
): Promise<void> => {
  setTimeout(async () => {
    if (entries) await getOperatorBookings(active.value);
  }, 400);
};

const switchTab = async (item: unknown): Promise<void> => {
  const tabItem = item as string;
  tab.value = tabItem;
  const newActiveValue = tabItem === "active";
  active.value = newActiveValue;
  await reloadBookings();
};

const switchTrainOperator = async (item: DropdownItem): Promise<void> => {
  const newTrainOperator = (item.value as TrainOperator).id;
  selectedTrainOperator.value = newTrainOperator;
  await reloadBookings();
};

const reloadBookings = async (): Promise<void> => {
  trainOperatorBookings.value = [];
  page.value = 0;
  size.value = 25;
  showSkeleton.value = true;
  await getOperatorBookings(active.value);
};

const columnsArray = (tabName: string): string[] => {
  return tabName === "active" ? ACTIVE_COLUMNS : COMPLETED_COLUMNS;
};

onMounted(async () => {
  await getTrainOperators();
  await getOperatorBookings(active.value);
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await getTrainOperators();
      await reloadBookings();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
.dynamic-width-container {
  max-width: 1920px;
}

@media screen and (max-width: 1920px) {
  .dynamic-width-container {
    max-width: 98vw;
  }
}
</style>
