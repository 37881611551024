export const ACTIVE_COLUMNS = [
  "type",
  "referenceNumber",
  "trainOperatorBookings.containerNumber",
  "containerLength",
  "netWeight",
  "emptyWeight",
  "forwarder",
  "driver",
  "licensePlate",
  "slotTime",
  "trainOperatorBookings.lastLoadingTime",
  "trainOperatorBookings.trainNumber",
];
export const COMPLETED_COLUMNS = [
  "type",
  "referenceNumber",
  "trainOperatorBookings.containerNumber",
  "containerLength",
  "netWeight",
  "emptyWeight",
  "forwarder",
  "driver",
  "licensePlate",
  "slotTime",
  "trainOperatorBookings.lastLoadingTime",
  "transhipmentDate",
  "trainOperatorBookings.trainNumber",
];
