<template>
  <v-container fluid class="pa-0">
    <v-app-bar color="#0b295b">
      <v-toolbar-title class="d-flex align-center">
        <v-spacer />
        <v-btn variant="text" color="white">
          {{
            `${$t("current")} ${isAdmin(admin?.roles ?? []) ? $t("admin") : $t("user")}: ${admin?.firstName} ${admin?.lastName}`
          }}
        </v-btn>
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        @click="logout"
        color="error"
        size="small"
        class="mr-2"
        variant="elevated"
      >
        {{ $t("logOut") }}
      </v-btn>
    </v-app-bar>

    <v-container fluid class="d-flex flex-column justify-center align-center">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="text-h2 font-weight-bold" style="color: #0b295b">
            {{ $t("userManagement.title") }}
          </h1>
        </v-col>
      </v-row>

      <v-row v-if="filledData">
        <v-col cols="12">
          <UserSearchBar @submit="t => filterUsers(t)" />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-if="users.data.length !== 0" class="mt-8">
        <v-col cols="12">
          <ManagedUserTable
            @approve="p => approveUser(p)"
            @submit="p => addUserRevision(p)"
            @reset="p => resetIDNow(p)"
            :users="users.data"
          />
        </v-col>
      </v-row>

      <v-row v-if="users.data.length !== 0 && loading">
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-progress-linear indeterminate height="6" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script lang="ts" setup>
import UserSearchBar from "@/components/user-management/UserSearchBar.vue";
import ManagedUserTable from "@/components/user-management/ManagedUserTable.vue";
import { useUserManagementLogic } from "./user-management-logic";
import { isAdmin } from "@/utils/role-utils";

const {
  admin,
  filledData,
  users,
  loading,
  logout,
  approveUser,
  filterUsers,
  addUserRevision,
  resetIDNow,
} = useUserManagementLogic();
</script>

<style scoped>
.v-container {
  max-width: 100%;
}

.v-row {
  width: 100%;
}
</style>
