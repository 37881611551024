<template>
  <v-card
    class="card rounded-lg pa-0 ma-0"
    color="#3f5d85"
    width="100%"
    elevation="2"
    rounded
    data-testid="test-bookings-filter-row"
  >
    <v-card-title class="card-title pa-5">
      <v-row align-content="space-around" class="text-white">
        <v-col align-self="center" class="attribute-title">
          {{ $t("status") }}
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            label="bookingType"
            @change-item="filterAssigned"
            :translate="true"
            :items="translatedAttributes.bookingType"
            item-title="itemKey"
            item-value="itemValue"
          />
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <div class="free-field">
            <v-text-field
              theme="dark"
              :label="$t('referenceNumber')"
              @update:modelValue="
                (e: string) =>
                  assignFilterFreeText({ label: 'referenceNumber', value: e })
              "
            />
          </div>
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <div class="free-field">
            <v-text-field
              theme="dark"
              :label="$t('containerNumber')"
              @update:modelValue="
                (e: string) =>
                  assignFilterFreeText({ label: 'containerNumber', value: e })
              "
            />
          </div>
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            label="containerType"
            @change-item="filterAssigned"
            :items="translatedAttributes.containerType"
            item-title="itemKey"
            item-value="itemValue"
          />
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            label="truckerName"
            :items="translatedAttributes.truckerName"
            :item-title="getTruckerText"
            @change-item="filterAssigned"
            item-value="id"
          />
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            @change-item="filterAssigned"
            label="licensePlate"
            :items="translatedAttributes.licensePlate"
            item-title="itemKey"
            item-value="itemValue"
          />
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            @change-item="filterAssigned"
            label="terminal"
            :items="translatedAttributes.terminal"
            item-title="itemKey"
            item-value="itemValue"
          />
        </v-col>
        <v-col align-self="center" class="attribute-title">
          <TheDropdown
            class="mt-3"
            @change-item="filterAssigned"
            label="timeSlotId"
            :items="translatedAttributes.timeSlotId"
            :item-title="getTimeSlotText"
            item-value="id"
          />
        </v-col>
        <v-col v-if="spacer">
          <v-spacer />
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script setup lang="ts">
import { ref, toRefs, computed } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import { TimeSlot, TruckerForForwarderTO } from "@/services/client/generated";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./forwarder-bookings-overview-logic";

const i18n = useI18n();
const viewModel = getViewModel();

const props = defineProps<{
  attributes: Record<string, object[] | string[]>;
  spacer?: boolean;
}>();

const emits = defineEmits(["filter-addition"]);
const { attributes, spacer } = toRefs(props);
const debouncedTimerId = ref<NodeJS.Timeout | number | null>();

const filterAssigned = (payload: { label: string; value: string | number }) => {
  emits("filter-addition", payload);
};

const getTimeSlotText = (timeSlot: TimeSlot) => {
  return viewModel.parseTimeslot(timeSlot, i18n.locale.value);
};

const getTruckerText = (trucker: TruckerForForwarderTO) => {
  return `${trucker.firstName} ${trucker.lastName}`;
};

const assignFilterFreeText = (payload: { label: string; value: string }) => {
  const delayInMilliseconds = 300;
  if (debouncedTimerId.value) clearTimeout(debouncedTimerId.value);
  debouncedTimerId.value = setTimeout(
    () => filterAssigned(payload),
    delayInMilliseconds,
  );
};

const translatedAttributes = computed(() => {
  const translateItems = (items: string[]) =>
    items.map(item => {
      if (item != null) {
        const translatedItem: Record<string, string> = {};
        translatedItem.itemKey = i18n.t(item);
        translatedItem.itemValue = item;

        return translatedItem;
      }
    });

  return {
    bookingType: translateItems(attributes.value.bookingType as string[]),
    containerType: translateItems(attributes.value.containerType as string[]),
    truckerName: attributes.value.truckerName as object[],
    licensePlate: attributes.value.licensePlate as string[],
    terminal: attributes.value.terminal as string[],
    timeSlotId: attributes.value.timeSlotId as object[],
  };
});
</script>

<style lang="scss">
.free-field .v-select__selections {
  color: white !important;
}

.free-field .v-input .v-input__control .v-input__slot * {
  color: white !important;
}

.attribute-title {
  text-transform: uppercase;
  word-break: keep-all;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}
</style>
