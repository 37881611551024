<template>
  <v-card
    width="100%"
    elevation="10"
    class="rounded-lg"
    rounded
    color="#00295b"
  >
    <v-row class="pa-2">
      <v-col cols="3">
        <v-row align-content="center" justify="center">
          <v-col class="text-white font-weight-bold" align-self="center">
            {{ $t("selectTrucker") }}
          </v-col>
          <v-col class="mt-2">
            <TheDropdown
              :item-title="(e: TruckerForForwarderTO) => `${e.firstName} ${e.lastName}`"
              item-value="userId"
              :items="truckers"
              @change-item="selectTrucker"
              label="trucker"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="2" class="ma-2">
        <TheButton
          color="#ff005b"
          :title="$t('create')"
          @click="$emit('create-booking')"
          :is-disabled="!isTruckerSelected"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import TheDropdown from "@/components/TheDropdown.vue";
import TheButton from "@/components/TheButton.vue";
import { PropType, ref } from "vue";
import { TruckerForForwarderTO } from "@/services/client/generated";

defineProps({
  truckers: {
    type: Array as PropType<TruckerForForwarderTO[]>,
    required: true,
  },
});

const emit = defineEmits(["create-booking", "change-trucker"]);
const isTruckerSelected = ref<boolean>(false);

const selectTrucker = (truckerId: { label: string; value: string }) => {
  isTruckerSelected.value = truckerId.value.length !== 0;
  emit("change-trucker", truckerId);
};
</script>

<style scoped lang="scss"></style>
