<template>
  <v-container
    @click="handleRouting"
    :class="`page-card rounded-lg ${backgroundImage}Tile ${isVisible} pa-2`"
  >
    <div class="d-flex">
      <v-icon
        v-if="item.icon"
        class="pa-1"
        color="#ffffff"
        :icon="item.icon"
        :data-testid="`test-icon-${item.icon}`"
      />
    </div>
    <div class="pt-14 py-16 pb-16">
      <div align="center" justify="center" class="fill-height">
        <span class="page-title">{{ uppercaseTitle }}</span>
      </div>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import PageCardItem from "@/internal-models/page-card-item";

const props = defineProps<{
  item: PageCardItem;
  usable: boolean;
}>();

const router = useRouter();

const handleRouting = (): void => {
  if (!props.usable) return;
  router.push(props.item?.page);
};

const isVisible = computed((): string => {
  return props.usable ? "cursor-pointer" : "opacity";
});

const uppercaseTitle = computed((): string => {
  return props.item.title.toUpperCase();
});

const backgroundImage = computed((): string => {
  switch (props.item?.background) {
    case "graph":
      return "conrooUser";
    case "operator-chair":
      return "trainOperator";
    case "trucks":
      return "forwarders";
    default: // & terminals
      return "terminalOperator";
  }
});
</script>

<style lang="scss" scoped>
.page-card {
  width: 100%;
  max-width: 1200px;
}
.page-title {
  font-weight: bold;
  color: white;

  @media only screen and (min-width: 400px) {
    font-size: 0.8rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.2rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.4rem;
  }
}

.opacity {
  opacity: 0.35;
}

.cursor-pointer {
  cursor: pointer;
}

.router-link {
  text-decoration: none;
  color: inherit;
}

.terminalOperatorTile {
  background-image: url("@/assets/thumbnails/terminals.png");
}

.conrooUserTile {
  background-image: url("@/assets/thumbnails/graph.png");
}

.trainOperatorTile {
  background-image: url("@/assets/thumbnails/operator-chair.png");
}

.forwardersTile {
  background-image: url("@/assets/thumbnails/trucks.png");
}
</style>
